<template>
  <div id="geographic-extent"></div>
</template>

<script>
import L from 'leaflet';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import geocoderMixins from '@/mixins/leaflet/geocoderMixins';
import scaleMixins from '@/mixins/leaflet/scaleMixins';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  name: 'ShowCompanyGeographicExtentOnMap',
  mixins: [geocoderMixins, scaleMixins],
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mapaId: 'geographic-extent',
      map: null,
      layerControl: null,
      polygon: null,
      popup: null
    };
  },
  mounted() {
    this.drawMap();
    this.addPolygonPoints();
    // Atualiza as coordenadas do ponto selecionado
    this.map.on('click', (e) => {
      const { lat, lng } = e.latlng;
      this.addPopup(lat, lng);
    });
  },
  computed: {
    polygonPoints() {
      const { minx, maxx, miny, maxy } = this.company;
      return [
        [miny, minx],
        [miny, maxx],
        [maxy, maxx],
        [maxy, minx]
      ];
    }
  },
  methods: {
    drawMap() {
      this.configMap(5);
      this.map.setView([-13.649319, -56.1236542]);
      setTimeout(() => {
        const { capital } = this.company;
        const lng = capital[0];
        const lat = capital[1];
        if (!(lat && lng)) return;
        this.map.setView([lat, lng]);
      }, 2000);
    },
    configMap(zoom) {
      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        loadingControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });
      this.layerControl = L.control.layers(mapboxConfig.getBaseLayers());
      this.layerControl.addTo(this.map);
      this.createScaleControl(L).addTo(this.map);
      this.createGeocoderControl(L, this.map).addTo(this.map);
    },
    addPolygonPoints() {
      if (this.polygon) this.map.removeLayer(this.polygon);
      this.polygon = L.polygon(this.polygonPoints);
      this.polygon.addTo(this.map);
    },
    addPopup(lat, lng) {
      if (this.popup) this.map.removeLayer(this.popup);
      this.popup = L.popup()
        .setLatLng([lat, lng])
        .setContent(
          `
         <strong>Ponto selecionado:</strong><br>
         Latitude: ${lat}<br>
         Longitude: ${lng}
      `
        )
        .openOn(this.map);
    }
  },
  watch: {
    polygonPoints() {
      this.addPolygonPoints();
    }
  }
};
</script>

<style>
#geographic-extent {
  height: 400px;
  z-index: 0;
}
</style>
